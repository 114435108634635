import { useLazyQuery } from '@apollo/client';
import { CaretDown } from '@phosphor-icons/react';
import { Button, ConfigProvider, Flex, Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { DROPDOWN_LIST } from '../../../common/constants';
import { handleGraphQlSuccess } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';
import { InitiateVoiceFormSubmissionInputType, Palette } from '../form.types';
import { INITIATE_VOICE_CALL } from '../graphql/queries';

export default function WebCallPreview({
  activeTheme,
}: {
  activeTheme: Palette;
}) {
  const [form] = useForm();
  const { params } = useRouter();
  const formId = params?.id as string;
  const [isLoading, setIsLoading] = useState(false);
  const [initiateVoiceCallMutate, { loading }] = useLazyQuery(
    INITIATE_VOICE_CALL,
    {
      fetchPolicy: 'network-only',
      onError: () => {},
    },
  );
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{ width: 150 }}
        options={DROPDOWN_LIST.countryList}
        showSearch
        suffixIcon={<CaretDown size={18} color={activeTheme?.textColor} />}
      ></Select>
    </Form.Item>
  );

  const handleFinish = (formValues: InitiateVoiceFormSubmissionInputType) => {
    const { prefix, phone, ...restFormValues } = formValues;
    const payload = {
      ...restFormValues,
      formId: formId,
      phoneNumber: `${prefix}${phone}`,
    };
    // initiateVoiceCallMutate({
    //   variables: {
    //     data: payload,
    //   },
    //   onCompleted: (res) => {
    //     handleGraphQlSuccess(res.initiateVoiceFormSubmission?.message);
    //     form.resetFields();
    //   },
    // });
    setIsLoading(true);

    fetch(
      'https://voice-call-form-submission-dev.morning-union-20af.workers.dev/api/v1/make-call',
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        handleGraphQlSuccess(res.message);
        form.resetFields();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: 'var(--surface-secondary)',
          colorPrimary: '#a9aeb1',
          fontFamily: activeTheme?.fontFamily,
        },
        components: {
          Form: {
            labelColor: activeTheme?.textColor,
            labelFontSize: 14,
            verticalLabelPadding: '10px 0',
            itemMarginBottom: 0,
            fontWeightStrong: 500,
          },
          Segmented: {
            trackBg: activeTheme?.textColor,
            itemColor: activeTheme?.bgPrimary,
            itemSelectedColor: activeTheme?.textColor,
            itemSelectedBg: activeTheme?.bgPrimary,
          },
          Input: {
            borderRadius: 16,
            // colorBorder: 'var(--border-primary)',
            colorText: activeTheme?.textColor,
            colorTextPlaceholder: activeTheme?.textColor,
            colorBgContainer: 'transparent',
            colorBorder: activeTheme?.textColor,
          },
          InputNumber: {
            borderRadius: 16,
            colorText: activeTheme?.textColor,
            colorTextPlaceholder: activeTheme?.textColor,
            colorBgContainer: 'transparent',
            colorBorder: activeTheme?.textColor,
          },
          Select: {
            colorText: activeTheme?.textColor,
            optionActiveBg: activeTheme?.bgPrimary,
            colorBgElevated: activeTheme?.bgPrimary,
            optionSelectedColor: activeTheme?.bgPrimary,
            optionSelectedBg: activeTheme?.textColor,
            colorBorder: activeTheme?.textColor,
            selectorBg: 'transparent',
          },
          Button: {
            colorPrimary: activeTheme?.textColor,
            colorPrimaryHover: activeTheme?.textColor,
            colorTextLightSolid: activeTheme?.bgPrimary,
            defaultColor: activeTheme?.textColor,
            defaultBg: 'transparent',
            defaultBorderColor: activeTheme?.textColor,
            defaultHoverBg: activeTheme?.textColor,
            defaultHoverColor: activeTheme?.bgPrimary,
          },
          DatePicker: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
          },
        },
      }}
    >
      <Flex justify="center" align="center">
        <Button type="primary" shape="round" loading={isLoading} size="large">
          Click preview to start
        </Button>
      </Flex>
    </ConfigProvider>
  );
}
