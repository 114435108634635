import { useLazyQuery, useQuery } from '@apollo/client';
import { Check, Info, Warning, X } from '@phosphor-icons/react';
import { Alert, Divider, Flex, Skeleton, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { lowerCase, map, startCase } from 'lodash';
import { useContext, useRef, useState } from 'react';
import {
  SubscriptionPlan,
  SubscriptionPlanType,
  SubscriptionPurchaseStatus,
  WorkspaceMemberRoles,
  WorkspacesResponse,
} from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import {
  DEFAULT_MONTH_FORMAT,
  DEFAULT_PLAN_SORT,
  initialWorkspaceListFilter,
} from '../../common/constants';
import { currencyCodeToSymbol } from '../../common/utils';
import PremiumLogo from '../../components/common/PremiumLogo';
import CommonButton from '../../components/primitives/CommonButton';
import CommonTag from '../../components/primitives/CommonTag';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { GET_USER_ROLE } from '../auth/graphql/queries';
import { PaddleCheckout } from '../payment/PaddleCheckout';
import { GET_USERS_WORKSPACES } from '../workspace/graphql/queries';
import { GET_FEATURE_LIST, GET_PLAN_LIST } from './graphql/queries';
import LifetimePurchaseBanner from './LifetimePurchaseBanner';
import { PlanFeature, PlanType } from './profile.types';

export default function PlanBilling() {
  const ref = useRef<HTMLDivElement>(null);
  const { params } = useRouter();
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const { dispatch, getWorkspaceId, updateCurrentUser } = useContext(
    AppContext,
  ) as AppContextType;
  const { loading: fetchUserLoading } = useQuery(GET_USER_ROLE, {
    fetchPolicy: 'network-only',
    context: {
      headers: {
        'x-workspace-id': params?.id,
      },
    },
    onCompleted: (res) => {
      setIsOwner(
        res?.currentUser?.currentWorkspace?.workspaceMembers?.[0]?.role ===
          WorkspaceMemberRoles.Owner,
      );
    },
    onError: () => {},
  });

  const [fetchWorkspaces, { loading }] = useLazyQuery(GET_USERS_WORKSPACES, {
    fetchPolicy: 'network-only',
    onError: () => {},
    onCompleted: (res) => {
      dispatch({
        type: AppActionType.userWorkspaces,
        data: res.workspaces as WorkspacesResponse,
      });
    },
  });

  const handleScroll = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const FeatureListItem = ({ list }: { list: string[] }) => {
    return (
      <ul className="mt-16">
        {map(list, (text) => {
          return (
            <li>
              <Check color="var(--system-green-dark)" size={20} weight="bold" />
              {text}
            </li>
          );
        })}
        <li
          onClick={handleScroll}
          className="cursor-pointer w-max-content text-underline"
        >
          <svg height={16} width={16}></svg>& More...
        </li>
      </ul>
    );
  };

  const PlanList = () => {
    const { loading, refetch, data } = useQuery(GET_PLAN_LIST, {
      fetchPolicy: 'cache-and-network',
      context: {
        headers: {
          'x-workspace-id': params?.id,
        },
      },
      variables: {
        sort: DEFAULT_PLAN_SORT,
      },
      onError: () => {},
    });

    const handlePaymentSuccess = async () => {
      const currentWorkspaceId = getWorkspaceId();
      if (currentWorkspaceId && currentWorkspaceId === params?.id) {
        updateCurrentUser(currentWorkspaceId, async () => {
          refetch();
          await fetchWorkspaces({
            variables: initialWorkspaceListFilter,
          });
        });
      } else {
        refetch();
        await fetchWorkspaces({
          variables: initialWorkspaceListFilter,
        });
      }
    };

    const handleSubscriptionCancel = async () => {
      const currentWorkspaceId = getWorkspaceId();
      if (currentWorkspaceId && currentWorkspaceId === params?.id) {
        updateCurrentUser(currentWorkspaceId, async () => {
          refetch();
          await fetchWorkspaces({
            variables: initialWorkspaceListFilter,
          });
        });
      } else {
        refetch();
        await fetchWorkspaces({
          variables: initialWorkspaceListFilter,
        });
      }
    };

    const premiumPlan = data?.subscriptionPlanKeyFeatures?.data?.filter(
      (item) => lowerCase(item?.label || '') === PlanType.PREMIUM,
    )?.[0];

    const dueDate = premiumPlan?.paymentDueAt;

    if (loading || fetchUserLoading) {
      return <Skeleton active className="mb-24" />;
    }

    if (
      data?.subscriptionPlanKeyFeatures?.activeSubscriptionPlan?.type ===
      SubscriptionPlanType.Ltd
    ) {
      return <LifetimePurchaseBanner />;
    }

    const formattedDueDate = dayjs(dueDate)?.format(DEFAULT_MONTH_FORMAT);
    const formattedFutureDueDate = dayjs(dueDate)
      .add(7, 'day')
      ?.format(DEFAULT_MONTH_FORMAT);
    return (
      <>
        <div className="subscribe-modal zinq-modal">
          {premiumPlan?.workspaceSubscriptionStatus ===
            SubscriptionPurchaseStatus.PaymentDue &&
            dueDate && (
              <div className="mb-32">
                <Alert
                  type="error"
                  message={`Auto-Payment has failed on ${formattedDueDate}, your plan will expire in ${formattedFutureDueDate} days on ${formattedFutureDueDate}.`}
                  className="w-full text-sm"
                  icon={
                    <Warning
                      size={16}
                      weight="fill"
                      color="var(--danger-on-surface)"
                    />
                  }
                  showIcon
                  closable={{
                    closeIcon: <X color="var(--content-primary)" size={20} />,
                  }}
                />
              </div>
            )}
          <div className="zinq-modal-content">
            <div className="subscribe-modal-wrapper">
              <div className="subscribe-plan-card-wrapper">
                {map(
                  data?.subscriptionPlanKeyFeatures?.data,
                  (item: SubscriptionPlan) => {
                    const status = item?.workspaceSubscriptionStatus;
                    return (
                      <div className="subscribe-plan-card">
                        <Flex
                          justify="space-between"
                          align="center"
                          className="mb-8"
                        >
                          {lowerCase(item.label!) !== PlanType.FREE ? (
                            <div className="tag premium">
                              PREMIUM <PremiumLogo />
                            </div>
                          ) : (
                            <div className="tag">{item.label}</div>
                          )}

                          {status === SubscriptionPurchaseStatus.Active && (
                            <CommonTag className="w-max-content current-plan-tag medium">
                              Current Plan
                            </CommonTag>
                          )}
                        </Flex>

                        <Title
                          level={2}
                          className="font-secondary semi-bold text-content-primary"
                        >
                          {lowerCase(item.label!) !== PlanType.FREE &&
                            item.type == SubscriptionPlanType.Monthly &&
                            `${currencyCodeToSymbol(item!.currency!)}${item!.price!}/month`}
                        </Title>
                        {/* <p className="text-sm mt-16">{item.description}</p> */}
                        {item.keyFeatures && (
                          <FeatureListItem
                            list={item.keyFeatures as string[]}
                          />
                        )}
                        <div className="plan-button-account-page">
                          {isOwner &&
                            lowerCase(item.label!) !== PlanType.FREE && (
                              <PaddleCheckout
                                onPurchaseSuccess={handlePaymentSuccess}
                                onSubscriptionCancel={handleSubscriptionCancel}
                                plan={item}
                                showCancelBtn
                                workspaceId={params.id!}
                              />
                            )}
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
              <div className="text-center text-sm mt-24 subscribe-modal-footer-text">
                ZINQ AI is still in beta.
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const FeatureList = () => {
    const { loading, refetch, data, error } = useQuery(GET_FEATURE_LIST, {
      fetchPolicy: 'cache-and-network',
      context: {
        headers: {
          'x-workspace-id': params?.id,
        },
      },
      variables: {
        sort: DEFAULT_PLAN_SORT,
      },
      onError: () => {},
    });

    const handlePaymentSuccess = async () => {
      const currentWorkspaceId = getWorkspaceId();
      if (currentWorkspaceId && currentWorkspaceId === params?.id) {
        updateCurrentUser(currentWorkspaceId, async () => {
          refetch();
          await fetchWorkspaces({
            variables: initialWorkspaceListFilter,
          });
        });
      } else {
        refetch();
        await fetchWorkspaces({
          variables: initialWorkspaceListFilter,
        });
      }
    };

    const handleSubscriptionCancel = async () => {
      const currentWorkspaceId = getWorkspaceId();
      if (currentWorkspaceId && currentWorkspaceId === params?.id) {
        updateCurrentUser(currentWorkspaceId, async () => {
          refetch();
          await fetchWorkspaces({
            variables: initialWorkspaceListFilter,
          });
        });
      } else {
        refetch();
        await fetchWorkspaces({
          variables: initialWorkspaceListFilter,
        });
      }
    };

    const renderFeatureItem = (plan: SubscriptionPlan, keys: string[]) => {
      const planValue = plan?.features
        ?.filter((val: PlanFeature) => keys.includes(val.key))
        .map((val: PlanFeature) => val.featureLabel);

      if (!planValue || planValue.length === 0) return null;

      return planValue.map((label: string, index: number) => {
        switch (typeof label) {
          case 'boolean':
            return label ? (
              <Check color="#09091D" size={20} weight="bold" />
            ) : (
              <X color="#120B3B99" size={20} />
            );
          default:
            return (
              <p className="mb-0" key={index}>
                {String(label)}
              </p>
            );
        }
      });
    };

    const handleLabelRender = (item: PlanFeature) => {
      return (
        <Flex gap={8}>
          <Paragraph className="mb-0 medium text-sm flex items-center gap-8 no-wrap">
            {item.title}
            {item?.description && (
              <Tooltip title={item.description}>
                <Info size={16} className="cursor-pointer flex-shrink-0" />
              </Tooltip>
            )}
          </Paragraph>
          {item.comingSoon && (
            <CommonButton
              type="default"
              size="small"
              className="coming-soon-button"
            >
              Coming Soon
            </CommonButton>
          )}
        </Flex>
      );
    };

    if (loading || fetchUserLoading) {
      return <Skeleton active />;
    }

    const isLTD =
      data?.subscriptionPlans?.activeSubscriptionPlan?.type ===
      SubscriptionPlanType.Ltd; // check if user has LTD Plan

    const freePlan = data?.subscriptionPlans?.data?.filter(
      (item) => lowerCase(item?.label || '') === PlanType.FREE,
    )?.[0];

    const premiumPlan = !isLTD
      ? data?.subscriptionPlans?.data?.filter(
          (item) => lowerCase(item?.label || '') === PlanType.PREMIUM,
        )?.[0]
      : data?.subscriptionPlans?.activeSubscriptionPlan;

    if (!error) {
      return (
        <div className="feature-wrapper" ref={ref}>
          <div className={`feature-table mt-32 ${isLTD ? 'hide-before' : ''}`}>
            <table>
              <thead>
                <tr>
                  <th>
                    <Flex vertical gap={8}>
                      <Title level={2} className="mb-0 medium">
                        {isLTD ? 'Features' : 'Compare plans & features'}
                      </Title>
                      {isLTD && (
                        <Paragraph className="mb-0 text-content-secondary medium">
                          All the features we are building and available with
                          lifetime deal
                        </Paragraph>
                      )}
                    </Flex>
                  </th>
                  {!isLTD && (
                    <th>
                      <Title level={4} className="mb-0 medium">
                        {startCase(lowerCase(freePlan?.label || ''))}
                      </Title>
                    </th>
                  )}
                  <th>
                    {!isLTD && (
                      <Flex vertical gap={8} align="center">
                        <Flex gap={8} align="center" justify="center">
                          <Title level={4} className="mb-0 medium">
                            Premium (
                            {premiumPlan?.currency &&
                              premiumPlan?.price &&
                              `${currencyCodeToSymbol(premiumPlan.currency)}${premiumPlan.price}/month`}
                            )
                          </Title>
                          <PremiumLogo />
                        </Flex>
                        {isOwner && !isLTD && premiumPlan && (
                          <PaddleCheckout
                            onPurchaseSuccess={handlePaymentSuccess}
                            onSubscriptionCancel={handleSubscriptionCancel}
                            plan={premiumPlan}
                            workspaceId={params.id!}
                          />
                        )}
                      </Flex>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {map(
                  !isLTD
                    ? data?.subscriptionPlans?.data?.[0]
                        ?.categoryWiseGroupedFeatures
                    : data?.subscriptionPlans?.activeSubscriptionPlan
                        ?.categoryWiseGroupedFeatures,
                  (item) => {
                    return (
                      <>
                        <tr>
                          <th colSpan={isLTD ? 2 : 3}>
                            <Paragraph className="mb-0 text-m medium">
                              {item?.title}
                            </Paragraph>
                          </th>
                        </tr>
                        {map(item?.list, (subItem) => {
                          return (
                            <>
                              {subItem?.title && (
                                <tr>
                                  <th colSpan={isLTD ? 2 : 3}>
                                    <Paragraph className="mb-0 text-base medium">
                                      {subItem?.title}
                                    </Paragraph>
                                  </th>
                                </tr>
                              )}
                              {map(subItem?.features, (item) => {
                                return (
                                  premiumPlan &&
                                  freePlan &&
                                  item!.key! && (
                                    <tr>
                                      <td>
                                        {handleLabelRender(item as PlanFeature)}
                                      </td>
                                      {!isLTD && (
                                        <td>
                                          {renderFeatureItem(freePlan, [
                                            item!.key!,
                                          ])}
                                        </td>
                                      )}
                                      <td>
                                        {renderFeatureItem(premiumPlan, [
                                          item!.key!,
                                        ])}
                                      </td>
                                    </tr>
                                  )
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    );
                  },
                )}
              </tbody>
              {isOwner && !isLTD && (
                <tfoot>
                  <tr>
                    <td colSpan={2}></td>
                    <td className="text-center">
                      {premiumPlan && (
                        <PaddleCheckout
                          onPurchaseSuccess={handlePaymentSuccess}
                          onSubscriptionCancel={handleSubscriptionCancel}
                          plan={premiumPlan}
                          workspaceId={params.id!}
                        />
                      )}
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="plan-and-billing">
      <PlanList />
      <Divider dashed className="mb-32 mt-32" />
      <FeatureList />
    </div>
  );
}
