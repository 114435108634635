import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CaretDown,
  MagnifyingGlass,
  Trash,
  UserCircle,
} from '@phosphor-icons/react';
import {
  Col,
  Flex,
  Input,
  Row,
  Select,
  Table,
  TableProps,
  Tooltip,
} from 'antd';
import Paragraph, { ParagraphProps } from 'antd/es/typography/Paragraph';
import { debounce, lowerCase, startCase } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  SortOrder,
  WorkspaceMember,
  WorkspaceMemberRoles,
  WorkspaceMembersSortField,
  WorkspaceMemberStatus,
} from '../../__generated__/graphql';
import {
  DEFAULT_ITEMS_PER_PAGE,
  DROPDOWN_LIST,
  initialPaginationFilter,
  roleListMapping,
  ROUTES,
} from '../../common/constants';
import { handleGraphQlSuccess, hasFeatureAccess } from '../../common/utils';
import CommonDeleteModal from '../../components/common/CommonDeleteModal';
import InviteMembersModal from '../../components/common/InviteMembersModal';
import SubscribeModal from '../../components/common/SubscribeModal';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { FeatureName } from '../../types/common.type';
import { GET_USER_ROLE } from '../auth/graphql/queries';
import { FeatureKeys } from '../profile/profile.types';
import {
  DELETE_INVITE_MEMBER,
  RESEND_MEMBER_INVITE,
  UPDATE_MEMBER_ROLE,
} from './graphql/mutations';
import { GET_WORKSPACE_MEMBERS } from './graphql/queries';

export default function WorkspaceMembers() {
  const [open, setOpen] = useState(false);
  const { navigate } = useRouter();
  const { params } = useRouter();
  const [isModal, setIsModal] = useState(false);
  const [memberId, setMemberId] = useState('');
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] =
    useState<boolean>(false);
  const [dataList, setDataList] = useState<WorkspaceMember[] | null>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const initialFilters = {
    filter: { ...initialPaginationFilter, search: '' },
    sort: [
      {
        sortBy: SortOrder.Desc,
        sortOn: WorkspaceMembersSortField.CreatedAt,
      },
    ],
  };
  const [filterProps, setFilterProps] = useState(initialFilters);
  const [inviteCount, setInviteCount] = useState<number>(0);
  const [totalMembers, setTotalMembers] = useState<number>(0);
  const [paginationProps, setPaginationProps] = useState({
    total: 0,
    current: 1,
    pageSize: DEFAULT_ITEMS_PER_PAGE,
  });

  const [fetchMembers, { loading }] = useLazyQuery(GET_WORKSPACE_MEMBERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setDataList((res.workspaceMembers?.data as WorkspaceMember[]) || []);
      setPaginationProps({
        ...paginationProps,
        total: res.workspaceMembers?.count || 0,
      });
      setTotalMembers(res.workspaceMembers?.totalMembers || 0);
      setInviteCount(
        Number(
          hasFeatureAccess(
            res.workspaceMembers?.subscriptionPlan?.features,
            FeatureKeys.MAX_WORKSPACE_MEMBER,
          ),
        ),
      );
    },
    onError: () => {
      navigate(ROUTES.NOT_FOUND);
    },
  });

  const [updateMemberRoleMutate, { loading: updateRoleLoading }] = useMutation(
    UPDATE_MEMBER_ROLE,
    {
      context: {
        headers: {
          'x-workspace-id': params?.id,
        },
      },
      onError: () => {},
    },
  );

  const [resendInviteMutate, { loading: resendLoading }] = useMutation(
    RESEND_MEMBER_INVITE,
    {
      context: {
        headers: {
          'x-workspace-id': params?.id,
        },
      },
      onError: () => {},
    },
  );

  const [deleteUser, { loading: deleteLoading }] = useMutation(
    DELETE_INVITE_MEMBER,
    {
      context: {
        headers: {
          'x-workspace-id': params?.id,
        },
      },
      onError: () => {},
    },
  );

  const { loading: fetchUserLoading } = useQuery(GET_USER_ROLE, {
    fetchPolicy: 'network-only',
    context: {
      headers: {
        'x-workspace-id': params?.id,
      },
    },
    onCompleted: (res) => {
      setIsOwner(
        res?.currentUser?.currentWorkspace?.workspaceMembers?.[0]?.role ===
          WorkspaceMemberRoles.Owner,
      );
    },
    onError: () => {},
  });

  useEffect(() => {
    if (params?.id) {
      fetchMembers({
        variables: {
          ...filterProps,
          where: {
            workspaceUuid: params?.id,
          },
        },
      });
      setSearchTerm(filterProps?.filter?.search);
    }
  }, [params?.id, filterProps]);

  const debouncedSearch = useRef(
    debounce((term: string) => {
      setFilterProps((prev) => ({
        ...prev,
        filter: {
          ...initialFilters.filter,
          search: term,
        },
      }));
      setPaginationProps({ ...paginationProps, current: 1 });
    }, 300),
  ).current;

  const handleOnOk = () => {
    deleteUser({
      variables: {
        where: {
          memberId,
        },
      },
      onCompleted: (data) => {
        handleGraphQlSuccess(data.deleteMember?.message);
        setFilterProps(initialFilters);
        setPaginationProps({ ...paginationProps, current: 1 });
        setOpen(false);
      },
    });
  };

  const handleResendInvite = (invitationId: string) => {
    resendInviteMutate({
      variables: {
        data: {
          invitationId,
        },
      },
      onCompleted: (data) => {
        handleGraphQlSuccess(data.resendInvitation?.message);
      },
    });
  };

  const closeSubscriptionModal = () => {
    setIsSubscriptionModalVisible(false);
  };

  const TableHeader = ({
    text,
    className,
    ...rest
  }: ParagraphProps & { text: string }) => {
    return (
      <Paragraph
        className={`text-sm text-content-primary medium ${className}`}
        {...rest}
      >
        {text}
      </Paragraph>
    );
  };

  const columns: TableProps['columns'] = [
    {
      title: <TableHeader text="Name" />,
      render(value, record: WorkspaceMember) {
        const email = record?.member?.email || record?.email;
        return (
          <Flex vertical gap={4}>
            {record?.member?.name && (
              <Paragraph className="mb-0 text-sm text-content-primary medium">
                {record.member.name}
              </Paragraph>
            )}
            {email && (
              <Paragraph className="mb-0 text-sm text-content-secondary">
                {email}
              </Paragraph>
            )}
          </Flex>
        );
      },
    },
    {
      title: <TableHeader text="Role" className="text-right" />,
      render(value, record: WorkspaceMember) {
        const showSuffix = record?.role !== WorkspaceMemberRoles.Owner;
        const showDelete =
          record?.role !== WorkspaceMemberRoles.Owner && isOwner;
        const showInvite =
          record?.role !== WorkspaceMemberRoles.Owner &&
          record?.status !== WorkspaceMemberStatus.Accepted &&
          isOwner;

        return (
          <>
            <Flex gap={8} align="center" justify="flex-end">
              {showInvite && (
                <Paragraph
                  className={`mb-0 mr-16 text-sm text-system-blue-dark medium text-underline ${resendLoading ? 'pointer-none' : 'cursor-pointer'}`}
                  onClick={() => {
                    handleResendInvite(record?.id || '');
                  }}
                >
                  Resend Invite
                </Paragraph>
              )}
              <Select
                className="role-list"
                suffixIcon={
                  showSuffix ? (
                    <CaretDown size={16} color="var(--content-tertiary)" />
                  ) : null
                }
                options={DROPDOWN_LIST.roleList}
                optionRender={(option) => (
                  <Flex vertical gap={2}>
                    <Paragraph className="text-base medium mb-0">
                      {option?.label}
                    </Paragraph>
                    <Paragraph className="mb-0 white-space-normal text-content-tertiary text-sm">
                      {
                        roleListMapping?.[
                          option?.data.value as WorkspaceMemberRoles
                        ]
                      }
                    </Paragraph>
                  </Flex>
                )}
                getPopupContainer={() =>
                  document.getElementById('role-list-dropdown') as HTMLElement
                }
                value={
                  record?.role === WorkspaceMemberRoles.Owner
                    ? startCase(lowerCase(WorkspaceMemberRoles.Owner))
                    : record?.role
                }
                onChange={(value) => {
                  const index = dataList?.findIndex(
                    (item) => item.id === record?.id,
                  ) as number;
                  if (index !== -1) {
                    const updatedValue = [...(dataList as WorkspaceMember[])]; // clone to perform write access
                    updatedValue.splice(index, 1, {
                      ...dataList?.[index],
                      role: value as WorkspaceMemberRoles,
                    });
                    setDataList(updatedValue);
                  }
                  updateMemberRoleMutate({
                    variables: {
                      where: {
                        memberId: record?.id || '',
                      },
                      data: {
                        role: value as WorkspaceMemberRoles,
                      },
                    },
                    onCompleted: (data) => {
                      handleGraphQlSuccess(data.updateMemberRole?.message);
                    },
                  });
                }}
                disabled={
                  record?.role === WorkspaceMemberRoles.Owner ||
                  updateRoleLoading ||
                  !isOwner
                }
              />
              {showDelete && (
                <CommonButton
                  type="text"
                  shape="circle"
                  className="bg-surface-tertiary"
                  shadow={false}
                  icon={<Trash color="var(--danger-on-surface)" size={16} />}
                  onClick={() => {
                    setMemberId(record?.id || '');
                    setOpen(true);
                  }}
                />
              )}
            </Flex>
            <div id="role-list-dropdown" />
          </>
        );
      },
    },
  ];

  return (
    <div className="workspace-members">
      <div className="workspace-members-header mb-16">
        <Row gutter={16}>
          <Col md={18} xl={19}>
            <Input
              placeholder={`Search from members (${paginationProps?.total})...`}
              prefix={
                <MagnifyingGlass width={16} color="var(--content-secondary)" />
              }
              value={searchTerm}
              onChange={(e) => {
                const value = e.target.value;
                setSearchTerm(value);
                debouncedSearch(value);
              }}
            />
          </Col>
          <Col md={6} xl={5}>
            <Tooltip
              title={
                !isOwner ? 'Only workspace admins can invite new members.' : ''
              }
            >
              <CommonButton
                type="primary"
                loading={loading || fetchUserLoading}
                icon={
                  <UserCircle
                    size={14}
                    color="var(--content-inverse-primary)"
                    weight="fill"
                  />
                }
                onClick={() => {
                  if (inviteCount > 1) {
                    setIsModal(true);
                  } else {
                    setIsSubscriptionModalVisible(true);
                  }
                }}
                block
                premium={inviteCount <= 1}
                disabled={
                  (inviteCount <= totalMembers && inviteCount > 1) || !isOwner
                }
              >
                Invite ({`${totalMembers}/${inviteCount}`})
              </CommonButton>
            </Tooltip>
          </Col>
        </Row>
      </div>
      <div className="workspace-members-list">
        <Table
          columns={columns}
          dataSource={dataList || []}
          loading={loading || fetchUserLoading}
          locale={{
            emptyText: 'No Members',
          }}
          pagination={
            paginationProps?.total > DEFAULT_ITEMS_PER_PAGE
              ? {
                  onChange: (page, pageSize) => {
                    setFilterProps({
                      ...filterProps,
                      filter: {
                        ...filterProps?.filter,
                        skip: (page - 1) * DEFAULT_ITEMS_PER_PAGE,
                        limit: pageSize,
                      },
                    });
                    setPaginationProps({
                      ...paginationProps,
                      current: page,
                      pageSize: pageSize,
                    });
                  },
                  pageSize: initialFilters.filter.limit,
                  position: ['bottomLeft'],
                  total: paginationProps?.total,
                  current: paginationProps.current,
                }
              : false
          }
        />
      </div>
      {open && (
        <CommonDeleteModal
          open={open}
          title="Remove Member?"
          okText="Remove member"
          content="This action will revoke their access to this workspace and all its resources. Make sure this is what you want to do."
          onClose={() => {
            setOpen(false);
          }}
          onOk={() => {
            handleOnOk();
          }}
          cancelButtonProps={{
            disabled: deleteLoading,
          }}
          okButtonProps={{
            danger: true,
            loading: deleteLoading,
            type: 'text',
            icon: (
              <Trash
                size={16}
                color="var(--danger-on-surface)"
                weight="regular"
              />
            ),
          }}
        />
      )}
      {isModal && (
        <InviteMembersModal
          title="Invite Users"
          onConfirm={() => {
            setIsModal(false);
            setFilterProps(initialFilters);
            setPaginationProps({ ...paginationProps, current: 1 });
          }}
          isVisible={isModal}
          onCancel={() => {
            setIsModal(false);
          }}
        />
      )}
      {isSubscriptionModalVisible && (
        <SubscribeModal
          visible={isSubscriptionModalVisible}
          onCancel={closeSubscriptionModal}
          featureName={FeatureName.Invite}
          onUpgrade={() => {
            setFilterProps(initialFilters);
          }}
        />
      )}
    </div>
  );
}
