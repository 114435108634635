import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CaretRight,
  CodeBlock,
  CopySimple,
  LinkSimple,
  Plus,
  Trash,
  Warning,
} from '@phosphor-icons/react';
import { Alert, Dropdown, Empty, Flex, Popconfirm, Spin, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { isEmpty, lowerCase, map, startCase } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  FormShare,
  FormShareType,
  FormStatus,
  FormSubmissionType,
  ListFormShareSortOnField,
  SortOrder,
} from '../../../../__generated__/graphql';
import { AppContext } from '../../../../AppContext';
import {
  DEFAULT_EMBED_CONFIGS,
  DEFAULT_FORM_EMBED_TITLE,
  DEFAULT_FORM_SHARE_TITLE,
  DEFAULT_ITEMS_PER_PAGE,
  initialPaginationFilter,
  initialSort,
} from '../../../../common/constants';
import {
  copyToClipboard,
  getFormLink,
  handleGraphQlSuccess,
  hasFeatureAccess,
} from '../../../../common/utils';
import SubscribeModal from '../../../../components/common/SubscribeModal';
import CommonButton from '../../../../components/primitives/CommonButton';
import useFeatureAccess from '../../../../hooks/useFeatureAccess';
import useRouter from '../../../../hooks/useRouter';
import { AppContextType } from '../../../../types/appContext.type';
import { FeatureName, Permissions } from '../../../../types/common.type';
import { FeatureKeys } from '../../../profile/profile.types';
import { CREATE_FORM_SHARE, DELETE_FORM_SHARE } from '../../graphql/mutations';
import { GET_FORM_SHARES } from '../../graphql/queries';
import CommonDeleteMessage from '../utils/CommonDeleteMessage';
import { CommonPageTypes } from './pages.types';

export default function ShareList({ onClick }: CommonPageTypes) {
  const { params } = useRouter();
  const formId = params?.id as string;
  const [shares, setShares] = useState<FormShare[]>([]);
  const {
    state: { formSettings, currentUser },
    hasPermission,
  } = useContext(AppContext) as AppContextType;
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(0);

  const [getFormShares, { loading }] = useLazyQuery(GET_FORM_SHARES, {
    variables: {
      where: {
        formId,
      },
      filter: initialPaginationFilter,
      sort: initialSort,
    },
    onCompleted: (res) => {
      setShares(res.formShares?.data as FormShare[]);
      setHasMore(Number(res.formShares?.count) > DEFAULT_ITEMS_PER_PAGE);
      setCount(res.formShares?.count || 0);
    },
    onError() {},
    fetchPolicy: 'network-only',
  });

  const {
    checkFeatureAccess,
    isSubscriptionModalVisible,
    isUpgradeSuccessfulModalVisible,
    handleUpgradeSuccess,
    closeSubscriptionModal,
    closeUpgradeSuccessfulModal,
  } = useFeatureAccess();

  const [createFormShare, { loading: createLoading }] = useMutation(
    CREATE_FORM_SHARE,
    {
      onError: () => {},
    },
  );

  const editAccess = hasPermission(Permissions.WRITE);

  useEffect(() => {
    if (formId) {
      getFormShares();
    }
  }, [formId]);

  const ShareCard = ({
    item,
    hideButtons,
  }: {
    item: FormShare;
    hideButtons: boolean;
  }) => {
    const [deleteFormShare, { loading: deleteLoading }] = useMutation(
      DELETE_FORM_SHARE,
      {
        onError: () => {},
      },
    );

    const handleDelete = (id: string) => {
      deleteFormShare({
        variables: {
          where: {
            formShareId: id,
          },
        },
        onCompleted: (res) => {
          handleGraphQlSuccess(res.deleteFormShare?.message);
          setShares(shares?.filter((item) => item?.id !== id));
        },
      });
    };

    return (
      <Flex
        className="share-card"
        justify="space-between"
        align="center"
        onClick={() => {
          if (onClick) {
            onClick(item.id as string);
          }
        }}
      >
        <Flex align="center" gap={12}>
          <CommonButton
            shape="circle"
            size="small"
            icon={
              item?.type === FormShareType.Embed ? (
                <CodeBlock size={18} color="var(--content-primary)" />
              ) : (
                <LinkSimple size={18} color="var(--content-primary)" />
              )
            }
          ></CommonButton>
          <Flex vertical gap={4}>
            <Paragraph className="mb-0 text-base semi-bold text-content-primary">
              {item.title}
            </Paragraph>
            <Paragraph className="mb-0">
              {item?.formType === FormSubmissionType.Conversation
                ? `Default | ${startCase(lowerCase(item?.type || ''))}`
                : `${startCase(lowerCase(item?.formType || ''))} | ${startCase(lowerCase(item?.type || ''))}`}
            </Paragraph>
          </Flex>
        </Flex>
        <Flex gap={12} align="center">
          <Tooltip
            title={
              item?.type === FormShareType.Embed ? 'Copy Code' : 'Copy Link'
            }
          >
            <CommonButton
              type="text"
              size="small"
              shape="circle"
              className="bg-surface-tertiary"
              shadow={false}
              icon={<CopySimple color="var(--content-primary)" size={16} />}
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(
                  getFormLink(
                    item?.key || '',
                    {
                      customLink: item.customLink!,
                      domainType: item.domainType!,
                    },
                    item?.embedConfigs,
                  ),
                );
              }}
            ></CommonButton>
          </Tooltip>
          {!hideButtons && editAccess && (
            <Tooltip title="Delete">
              <Popconfirm
                title={
                  <CommonDeleteMessage message="This will deactivate already shared links." />
                }
                okText="Yes"
                className="hover-show"
                onConfirm={(e) => {
                  e?.stopPropagation();
                  handleDelete(item.id!);
                }}
                onCancel={(e) => e?.stopPropagation()}
                placement="right"
                okButtonProps={{
                  block: true,
                  icon: <DeleteOutlined />,
                  danger: true,
                }}
                cancelButtonProps={{ block: true }}
                icon={null}
              >
                <CommonButton
                  size="small"
                  type="text"
                  shape="circle"
                  className="bg-surface-tertiary"
                  shadow={false}
                  loading={deleteLoading}
                  onClick={(e) => e.stopPropagation()}
                  icon={<Trash color="var(--danger-on-surface)" size={16} />}
                />
              </Popconfirm>
            </Tooltip>
          )}
          <CaretRight
            color="var(--content-tertiary)"
            size={16}
            className="cursor-pointer"
          />
        </Flex>
      </Flex>
    );
  };

  const addShareList = [
    {
      key: FormShareType.Link,
      label: 'Link',
      icon: <LinkSimple size={16} />,
    },
    {
      key: FormShareType.Embed,
      label: 'Embed',
      icon: <CodeBlock size={16} weight="fill" />,
    },
  ];

  const handleCreateShare = (type: FormShareType) => {
    createFormShare({
      variables: {
        data: {
          title: `${type === FormShareType.Embed ? DEFAULT_FORM_EMBED_TITLE : DEFAULT_FORM_SHARE_TITLE} ${count + 1}`,
          type,
          formType: FormSubmissionType.Conversation,
          embedConfigs:
            type === FormShareType.Embed ? DEFAULT_EMBED_CONFIGS.IFRAME : {}, // add default iframe
        },
        where: {
          formId,
        },
      },
      onCompleted: (res) => {
        if (onClick) {
          onClick(res.createFormShare?.data?.id as string);
        }
      },
    });
  };

  // load more func when last element becomes visible
  const loadMore = () => {
    getFormShares({
      variables: {
        where: {
          formId,
        },
        filter: {
          limit: DEFAULT_ITEMS_PER_PAGE,
          skip: shares?.length,
        },
        sort: {
          sortBy: SortOrder.Desc,
          sortOn: ListFormShareSortOnField.CreatedAt,
        },
      },
      onCompleted: (res) => {
        const mergedData = [
          ...shares,
          ...(res.formShares?.data as FormShare[]),
        ];
        setShares(mergedData);
        setHasMore(Number(res.formShares?.count) > mergedData.length);
        setCount(res.formShares?.count || 0);
      },
    });
  };

  const observer = useRef<IntersectionObserver | null>(null);

  const lastItemRef = (node: HTMLDivElement | null) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    });
    if (node) observer.current.observe(node);
  };

  const hasShareAccess = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.MULTI_FORM_LINK,
  );

  return (
    <>
      <Flex className="scroll-wrapper sharing" vertical>
        <Flex gap={24} className="scroll-wrapper-header" vertical>
          <Flex className="w-full" justify="space-between" align="center">
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-m semi-bold text-content-primary">
                Share your form
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Add a share to setup share settings.
              </Paragraph>
            </Flex>
            <Dropdown
              disabled={createLoading || !editAccess}
              menu={{
                items: addShareList,
                onClick: ({ key }) => {
                  checkFeatureAccess(FeatureKeys.MULTI_FORM_LINK, () => {
                    handleCreateShare(key as FormShareType);
                  });
                },
              }}
              placement="bottom"
              trigger={['click']}
            >
              <CommonButton
                type="text"
                icon={<Plus size={14} />}
                title="Add Share"
                premium={!hasShareAccess}
                loading={createLoading}
              >
                Add
              </CommonButton>
            </Dropdown>
          </Flex>
        </Flex>
        <div className="scroll-wrapper-body">
          {formSettings?.status === FormStatus.Draft && (
            <Alert
              message="This form is not published. Please publish it before sharing."
              type="warning"
              icon={
                <Warning
                  size={16}
                  weight="fill"
                  color="var(--caution-on-surface)"
                />
              }
              className="mb-16"
              showIcon
            />
          )}

          {/* Loading state */}
          <Spin spinning={loading}>
            <Flex vertical gap={16}>
              {map(shares, (item: FormShare, idx) => {
                return (
                  <div ref={idx === shares?.length - 1 ? lastItemRef : null}>
                    <ShareCard
                      item={item}
                      hideButtons={shares?.length <= 2 && !hasShareAccess}
                    />
                  </div>
                );
              })}
              {loading && <Spin spinning />}
            </Flex>
          </Spin>

          {/* Empty state */}
          {isEmpty(shares) && !loading && <Empty description="No link found" />}
        </div>
      </Flex>

      {isSubscriptionModalVisible && (
        <SubscribeModal
          visible={isSubscriptionModalVisible}
          onCancel={closeSubscriptionModal}
          onUpgrade={handleUpgradeSuccess}
          featureName={FeatureName.MultiFormLink}
        />
      )}
      {/* {isUpgradeSuccessfulModalVisible && (
        <UpgradeSuccessful
          isVisible={isUpgradeSuccessfulModalVisible}
          onClose={closeUpgradeSuccessfulModal}
        />
      )} */}
    </>
  );
}
