import { useLazyQuery } from '@apollo/client';
import { DownloadSimple, Paperclip } from '@phosphor-icons/react';
import { Empty, Flex, Modal, ModalProps, Rate, Tag } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';
import { cloneDeep, isArray, isEmpty, map } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import {
  FormSubmission,
  ListSubmissionTranscriptsSortOnField,
  SortOrder,
} from '../../__generated__/graphql';
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_ITEMS_PER_PAGE,
  RESULT_DATE_FORMAT,
} from '../../common/constants';
import FormLoading from '../../modules/form/editor/utils/FormLoading';
import { FormFieldType, Transcript } from '../../modules/form/form.types';
import { GET_FORM_TRANSCRIPTS } from '../../modules/form/graphql/queries';

export default function TranscriptModal({
  open,
  metaData,
  isFetch,
  ...rest
}: ModalProps & { metaData: FormSubmission; isFetch: boolean }) {
  const [initialFilter] = useState({
    filter: {
      limit: DEFAULT_ITEMS_PER_PAGE,
      skip: 0,
    },
    sort: [
      {
        sortBy: SortOrder.Desc,
        sortOn: ListSubmissionTranscriptsSortOnField.CreatedAt,
      },
    ],
  });

  const [hasMore, setHasMore] = useState(true);
  const [fetchData, { loading }] = useLazyQuery(GET_FORM_TRANSCRIPTS, {
    fetchPolicy: 'network-only',
  });

  const observer = useRef<IntersectionObserver | null>(null);

  const [transcriptList, setTranscriptList] = useState<Transcript[]>([]);

  useEffect(() => {
    if (metaData?.id) {
      if (isFetch) {
        fetchData({
          variables: {
            ...initialFilter,
            where: {
              submissionId: metaData?.id as string,
            },
          },
          onCompleted: (res) => {
            // setHasMore(
            //   Number(res.submissionTranscript?.data?.transcript?.transcript) > DEFAULT_ITEMS_PER_PAGE,
            // );
            setHasMore(false);
            if (
              isArray(res.submissionTranscript?.data?.transcript?.transcript)
            ) {
              setTranscriptList(
                cloneDeep(
                  res.submissionTranscript?.data?.transcript?.transcript || [],
                ),
              );
            }
          },
        });
      } else {
        setTranscriptList(metaData?.voiceConversation as Transcript[]);
      }
    }
  }, [initialFilter, metaData]);

  // load more func when last element becomes visible
  const loadMore = () => {
    fetchData({
      variables: {
        ...initialFilter,
        // filter: {
        //   limit: DEFAULT_ITEMS_PER_PAGE,
        //   skip: transcriptList.length,
        // },
        where: {
          submissionId: metaData?.id as string,
        },
      },
      onCompleted: (res) => {
        const mergedData = [
          ...transcriptList,
          ...(res.submissionTranscript?.data?.transcript as Transcript[]),
        ];
        setTranscriptList(mergedData);
        // setHasMore(
        //   Number(res.submissionTranscripts?.count) > mergedData.length,
        // );
        setHasMore(false);
      },
    });
  };

  const lastItemRef = (node: HTMLDivElement | null) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    });
    if (node) observer.current.observe(node);
  };

  const UserResponseText = ({ text }: { text: string }) => {
    return (
      <Paragraph className="mb-0 medium text-content-secondary">
        {text}
      </Paragraph>
    );
  };

  const getTranscriptBody = (formSchema: Transcript) => {
    switch (formSchema?.retrievedAnswer?.type) {
      case FormFieldType.DATE:
        return dayjs(formSchema?.retrievedAnswer?.value)?.format(
          RESULT_DATE_FORMAT,
        );
      case FormFieldType.SHORT_TEXT:
      case FormFieldType.LONG_TEXT:
      case FormFieldType.EMAIL:
      case FormFieldType.NUMBER:
      case FormFieldType.PHONE_NUMBER:
      case FormFieldType.TIME:
      case FormFieldType.ADDRESS:
      case FormFieldType.CONTACT_INFO:
        return formSchema?.retrievedAnswer?.value || '-';
      case FormFieldType.FILE:
        return (
          <Flex gap={8} wrap>
            {isArray(formSchema?.retrievedAnswer?.value) &&
              map(formSchema?.retrievedAnswer?.value, (fileLink, idx) => {
                return (
                  <Tag
                    bordered={false}
                    className="download-btn text-content-primary"
                  >
                    <Paperclip size={14} color="var(--content-primary)" />
                    {`File ${idx + 1}`}
                    <DownloadSimple size={14} color="var(--content-primary)" />
                  </Tag>
                );
              })}
          </Flex>
        );
      case FormFieldType.LINEAR_SCALE:
        return (
          <UserResponseText text={formSchema?.retrievedAnswer?.value || '-'} />
        );
      case FormFieldType.SELECT:
      case FormFieldType.MULTI_SELECT:
        if (isArray(formSchema?.retrievedAnswer?.value)) {
          return formSchema?.retrievedAnswer?.value?.join(', ');
        }
        return formSchema?.retrievedAnswer?.value;
      // FIX ME
      // return (
      //   <Flex gap={8}>
      //     {map(formSchema.options, (opt) => {
      //       return (
      //         <CommonButton
      //           type={
      //             includes(formSchema?.retrievedAnswer?.value, opt)
      //               ? 'primary'
      //               : 'text'
      //           }
      //           size="small"
      //         >
      //           {opt}
      //         </CommonButton>
      //       );
      //     })}
      //   </Flex>
      // );
      case FormFieldType.RATE:
        return (
          <Rate
            defaultValue={Number(formSchema?.retrievedAnswer?.value)}
            disabled
          />
        );
      default:
        return formSchema?.retrievedAnswer?.value || '-';
    }
  };

  return (
    <Modal
      open={open}
      title={
        <div className="mb-24">
          <div className="text-lg medium text-content-primary mb-2">
            Transcript
          </div>
          <p className="text-sm text-content-secondary mt-4">
            {dayjs(metaData?.createdAt)?.format(DEFAULT_DATE_TIME_FORMAT)}
          </p>
        </div>
      }
      className="transcript-modal"
      {...rest}
    >
      <div>
        {!isEmpty(transcriptList) ? (
          map(transcriptList, (item, idx) => {
            return (
              <div
                className="mb-32"
                ref={
                  idx === transcriptList?.length - 1 && isFetch
                    ? lastItemRef
                    : null
                }
              >
                <div className="text-base medium text-content-primary">
                  <Markdown>{item?.aiQuestion}</Markdown>
                </div>
                <div className="mt-8">{getTranscriptBody(item)}</div>
              </div>
            );
          })
        ) : (
          <Empty description="No transcript found" />
        )}
        {loading && <FormLoading />}
      </div>
    </Modal>
  );
}
