import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CaretDown, CaretLeft, Trash } from '@phosphor-icons/react';
import {
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { map, omit } from 'lodash';
import {
  ContactDetails,
  TaskChannelType,
} from '../../../../../__generated__/graphql';
import { DROPDOWN_LIST } from '../../../../../common/constants';
import {
  formValidation,
  handleGraphQlError,
  handleGraphQlSuccess,
} from '../../../../../common/utils';
import CommonButton from '../../../../../components/primitives/CommonButton';
import useRouter from '../../../../../hooks/useRouter';
import { CREATE_CONTACT_FORM } from '../../../graphql/mutations';
import { GET_KNOWLEDGE_DETAIL } from '../../../graphql/queries';
import { CommonContactTypes } from '../pages.types';

export default function ContactForm({ onBack, contactId }: CommonContactTypes) {
  const [form] = Form.useForm();
  const { params } = useRouter();
  const formId = params?.id as string;

  const [createContactMutate, { loading }] = useMutation(CREATE_CONTACT_FORM, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      handleGraphQlError(error?.message);
    },
  });

  const [fetchKnowledge, { loading: knowledgeLoading }] = useLazyQuery(
    GET_KNOWLEDGE_DETAIL,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        form.setFieldsValue(res.knowledge?.data?.knowledge);
      },
      onError: (error) => {
        handleGraphQlError(error?.message);
      },
    },
  );

  // const handleOnFinish = () => {
  //   if (formId) {
  //     createKnowledgeMutate({
  //       variables: {
  //         data: {
  //           ...form.getFieldsValue(),
  //           formId,
  //         },
  //       },
  //       onCompleted: (res) => {
  //         handleGraphQlSuccess(res.createKnowledge?.message);
  //         onBack();
  //       },
  //     });
  //   }
  // };

  const handleOnFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const contactList = map(values?.contactDetails, (item) => {
          let obj = {};
          obj = { ...item, phoneNumber: `${item?.code}${item?.phoneNumber}` };
          return omit(obj, 'code');
        });
        createContactMutate({
          variables: {
            data: {
              contactDetails: contactList as ContactDetails[],
              formId,
            },
          },
          onCompleted: (res) => {
            handleGraphQlSuccess(res.createContacts?.message);
            onBack();
          },
        });
      })
      .catch(() => {
        // do nothing
      });
  };

  // useEffect(() => {
  //   if (contactId) {
  //     fetchKnowledge({
  //       variables: {
  //         where: {
  //           contactId,
  //         },
  //       },
  //     });
  //   } else {
  //     form.resetFields();
  //   }
  // }, [contactId]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#a9aeb1',
        },
        components: {
          Form: {
            labelColor: 'var(--content-secondary)',
            labelFontSize: 14,
            verticalLabelPadding: 0,
            itemMarginBottom: 0,
            fontWeightStrong: 500,
          },
          Input: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
            colorText: 'var(--content-primary)',
            colorBgContainer: 'var(--surface-secondary)',
          },
          InputNumber: {
            borderRadius: 16,
            colorText: 'var(--content-primary)',
            colorBorder: 'var(--border-primary)',
            colorBgContainer: 'var(--surface-secondary)',
          },
          Select: {
            borderRadius: 16,
            colorText: 'var(--content-primary)',
            colorBorder: 'var(--border-primary)',
            optionActiveBg: 'var(--surface-tertiary)',
            optionSelectedBg: 'var(--surface-tertiary)',
            colorBgContainer: '#ff0000',
          },
          Tag: {
            borderRadius: 16,
          },
          DatePicker: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
          },
        },
      }}
    >
      <Spin spinning={knowledgeLoading}>
        <Flex gap={12} wrap align="center" className="scroll-wrapper-header">
          <CommonButton
            type="text"
            onClick={() => onBack()}
            shape="circle"
            shadow={false}
            disabled={false}
            icon={<CaretLeft color="var(--content-primary)" size={20} />}
          />
          <Paragraph className="mb-0 text-content-primary text-base semi-bold">
            Add Contacts
          </Paragraph>
        </Flex>
        <div className="scroll-wrapper-body">
          <Form
            autoComplete="off"
            form={form}
            className="edit-template-form"
            onFinish={handleOnFinish}
            disabled={!!contactId}
          >
            <Form.List
              name="contactDetails"
              initialValue={[
                {
                  firstName: '',
                  lastName: '',
                  phoneNumber: '',
                  channel: TaskChannelType.Voice,
                  code: '+91',
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <div className="mb-16">
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex key={key} className="mb-18" vertical>
                      <Row gutter={[12, 24]} align="middle">
                        <Col span={24}>
                          <CommonButton
                            size="small"
                            type="text"
                            shape="circle"
                            className="bg-surface-tertiary"
                            shadow={false}
                            onClick={() => remove(name)}
                            icon={
                              <Trash
                                color="var(--danger-on-surface)"
                                size={16}
                              />
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'firstName']}
                            rules={[
                              formValidation.required,
                              formValidation.whitespace,
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'lastName']}
                            rules={[formValidation.whitespace]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'code']}
                            rules={[formValidation.whitespace]}
                          >
                            <Select
                              options={DROPDOWN_LIST.countryList}
                              popupMatchSelectWidth
                              showSearch
                              suffixIcon={<CaretDown size={18} />}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'phoneNumber']}
                            rules={[formValidation.required]}
                          >
                            <InputNumber
                              placeholder="Number"
                              className="w-full"
                              controls={false}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            {...restField}
                            name={[name, 'channel']}
                            rules={[formValidation.required]}
                          >
                            <Select
                              options={DROPDOWN_LIST.channelList}
                              popupMatchSelectWidth
                              showSearch
                              suffixIcon={<CaretDown size={18} />}
                              placeholder="Select channel"
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider className="w-full mt-24 mb-10" />
                    </Flex>
                  ))}
                  <Form.Item className="mb-12">
                    <CommonButton
                      type="text"
                      shadow={false}
                      onClick={() =>
                        add({
                          firstName: '',
                          lastName: '',
                          phoneNumber: '',
                          code: '+91',
                          channel: TaskChannelType.Voice,
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      Add more
                    </CommonButton>
                  </Form.Item>
                  {fields?.length > 0 && (
                    <Form.Item>
                      <CommonButton
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Submit
                      </CommonButton>
                    </Form.Item>
                  )}
                </div>
              )}
            </Form.List>
          </Form>
        </div>
      </Spin>
    </ConfigProvider>
  );
}
